import {FC, Fragment, useCallback, useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, {
    CardBody,
    CardFooter,
    CardLabel,
    CardTabItem,
    CardTitle,
} from "../../../components/bootstrap/Card";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Page from "../../../layout/Page/Page";
import SubHeader, {
    SubHeaderLeft,
} from "../../../layout/SubHeader/SubHeader";
import useFetch from "../../../hooks/useFetch";
import Spinner from "../../../components/bootstrap/Spinner";
import {OperationApiResponse} from "../../../type/operation-type";
import OperationTableComponent from "../../../components/table/Operation/OperationTableComponent";
import useFilters from "../../../hooks/useFilters";
import {OperationService} from "../../../services/operations/operationService";
import moment from "moment";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {ActiveTab, setActiveTab} from "../../../redux/activeTabSlice";
import EditOperationModal from "../../../components/operation/EditOperationModal";
import {PrivilegeContext} from "../../../components/priviledge/PriviledgeProvider";
import {WalletService} from "../../../services/wallet/walletService";
import WalletCupsClientCard from "./components/WalletCupsClientCard";
import Timeline, {TimelineItem} from "../../../components/extras/Timeline";
import {Link} from "react-router-dom";
import StatusLabel from "../../../components/status_label/StatusLabel";
import StatusWalletDropdown from "../../../components/StatusWalletDropdown";
import {ActionStatus} from "../../../type/actionStatus-type";
import {StatusService} from "../../../services/status/statusService";

const ACTIVE_TAB_SECTION = 'wallet_view';

const WalletView: FC = () => {

    const {id = "", tabURL = ""} = useParams<{ id: string, tabURL: string }>();

    const activeTab = useSelector((state: RootState) => state.activeTab.find((tab: ActiveTab) => tab.section === ACTIVE_TAB_SECTION));

    const [actionStatusesWallet, setActionStatusesWallet] = useState<any[]>([]);

    const dispatch = useDispatch();

    const {userCan} = useContext(PrivilegeContext);

    const {
        filters,
        updateFilters,
        updateFilterOrder,
        updatePage,
        updatePageSize,
    } = useFilters({cups: ''}, [], 50);

    const [dataWallet, setDataWallet] = useState<any>();
    const [showCreateOperationModal, setShowCreateOperationModal] = useState<boolean>(false);
    const [showEditOperationModal, setShowEditOperationModal] = useState<boolean>(false);
    const [operationSelected, setOperationSelected] = useState<string | null>();

    const getActionStatusWalletList = async (): Promise<ActionStatus[]|undefined> => {
        const response = await (await (new StatusService()).getChangeActionStatusesWallet({filter_filters:{entity: 'action_wallet', active: 1}, all: true})).getResponseData();
        if (response.success) {
            setActionStatusesWallet(response.data.actionStatuses);
        } else {
            return [];
        }
    }

    const fetchWalller = useCallback(async () => {
        const walletService = new WalletService();
        const response = await walletService.getEntry(id as string);
        console.log(response.getResponseData()?.data)
        setDataWallet(response.getResponseData()?.data);
        updateFilters({cups: response.getResponseData()?.data.cups});
        return response.getResponseData() as OperationApiResponse;
    }, [id]);

    const [data, loading, error, refetchClient] = useFetch(fetchWalller);

    const _handleOnOperationEdit = (operation: any) => {
        toast.success("Contrato editado correctamente");
        refetchClient();
    }

    const _handleOnEditModalVisilble = (visibility: boolean) => {
        if (visibility === false) {
            setOperationSelected(null);
        }
        setShowEditOperationModal(visibility);
    }


    const fetchOperations = useCallback(async () => {
        if (filters?.filter_filters?.cups === "") return;
        const operationService = new OperationService();
        const response = await operationService.getOperations(filters);
        return response.getResponseData() as OperationApiResponse;
    }, [filters, data]);


    const [Operationdata, Operationloading, Operationerror] =
        useFetch(fetchOperations);

    const navigate = useNavigate();

    useEffect(() => {
        getActionStatusWalletList();
    }, []);

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Fragment>
                        <Button
                            color="primary"
                            isLink
                            icon="ArrowBack"
                            onClick={() => navigate(-1)}
                        ></Button>
                        <CardLabel icon="Search" iconColor="info">
                            <CardTitle>Ver Cartera: {dataWallet?.cups}</CardTitle>
                        </CardLabel>
                    </Fragment>
                </SubHeaderLeft>
            </SubHeader>
            <Page container="fluid">{
                data ? (
                    <div className="row">
                        <div className="row">
                            <Card className="col-md-12" hasTab tabButtonColor="light">
                                <CardTabItem
                                    id="wallet"
                                    title="Datos de la Cartera"
                                    icon="Source"
                                >
                                    <CardBody>
                                        <div className="row mb-4">
                                            <div className="col-md-4">
                                                <FormGroup label="Estado de Cartera">
                                                    <div>
                                                        <StatusLabel status={data.currentWalletStatus} date={
                                                            data.statusDate
                                                                ? moment(data.statusDate.date).format(
                                                                    "DD/MM/yyyy HH:mm"
                                                                )
                                                                : undefined
                                                        }/>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-4">
                                                <FormGroup label="Estado de Último Contrato">
                                                    <div>
                                                        {data.lastOperationId === null && (<div className={'fw-bold text-danger'}>Operación eliminada</div>)}
                                                        {
                                                            data.lastOperationId !== null && (
                                                                <StatusLabel status={data.lastOperationId?.currentActionStatus} date={
                                                                    data.lastOperationId?.currentActionStatusDate
                                                                        ? moment(data.lastOperationId?.currentActionStatusDate.date).format(
                                                                            "DD/MM/yyyy HH:mm"
                                                                        )
                                                                        : undefined
                                                                }/>
                                                            )
                                                        }
                                                    </div>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-4">
                                                <FormGroup label="Estado de Acción">
                                                    <div>
                                                        <StatusLabel status={data.currentActionStatusWallet} date={
                                                            data.statusDate
                                                                ? moment(data.actionStatusDate.date).format(
                                                                    "DD/MM/yyyy HH:mm"
                                                                )
                                                                : undefined
                                                        }/>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/* DATA COLUMN */}
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <FormGroup label="IDENTIFICADOR DEL CONTRATO:">
                                                            <p> {data.operationIdentifier || "-"}</p>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <FormGroup label="CUPS:">
                                                            <p> {data.cups || "-"}</p>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <FormGroup label='TIPO DE TRÁMITE:'>
                                                            <p> {data.leadName || "-"}</p>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <FormGroup label='ORIGEN LEAD:'>
                                                            <p> {data.originName || "-"}</p>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div className="row mt-2">
                                                    <div className="col-md-4">
                                                        <FormGroup label="TIPO DE CONTRATO:">
                                                            <p> {data.operationTypeName || "-"} </p>
                                                        </FormGroup>
                                                    </div>
                                                    {
                                                        userCan('assign_to_agents', 'operations') || userCan('assign_to_user', 'operations') ?
                                                            <>
                                                                <FormGroup label='CREADA POR:'
                                                                           className={`${userCan('assign_to_user', 'operations') ? userCan('assign_to_agents', 'operations') ? 'col-md-3' : 'col-md-3' : 'd-none'}`}>
                                                                    <p>{data.userName}</p>
                                                                </FormGroup>
                                                                <FormGroup label='AGENTE:'
                                                                           className={`${userCan('assign_to_agents', 'operations') ? userCan('assign_to_user', 'operations') ? 'col-md-3' : 'col-md-3' : 'd-none'}`}>
                                                                    <p>{data.agentName}</p>
                                                                </FormGroup>
                                                            </>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardFooter className="align-items-start justify-content-start">
                                        <FormGroup label="CREADO:" className="col-md-2">
                                            <div className="d-inline-block ps-2">
                                                {data.createdAt ? moment(data.createdAt.date).format("DD-MM-YYYY HH:mm") : "-"}
                                            </div>
                                        </FormGroup>
                                        <FormGroup label="ACTUALIZADO:" className="col-md-2">
                                            <div className="d-inline-block ps-2">
                                                {data.updatedAt ? moment(data.updatedAt.date).format("DD-MM-YYYY HH:mm") : "-"}
                                            </div>
                                        </FormGroup>
                                        <FormGroup label="ACTIVACIÓN:" className="ms-4 col-md-2">
                                            <div className="d-inline-block ps-2">
                                                {data.activationDate ? moment(data.activationDate.date).format("DD-MM-YYYY HH:mm") : "-"}
                                            </div>
                                        </FormGroup>
                                        <FormGroup label="CLAWBACK:" className="ms-4 col-md-2">
                                            <div className="d-inline-block ps-2">
                                                {data.clawbackDate ? moment(data.clawbackDate?.date).format("DD-MM-YYYY HH:mm") : "-"}
                                            </div>
                                        </FormGroup>
                                        <FormGroup label="RENOVACIÓN:" className="ms-4 col-md-2">
                                            <div className="d-inline-block ps-2">
                                                {data.renewalDate ? moment(data.renewalDate?.date).format("DD-MM-YYYY HH:mm") : "-"}
                                            </div>
                                        </FormGroup>
                                    </CardFooter>
                                </CardTabItem>
                                <CardTabItem
                                    id="client"
                                    title="Datos del Cliente"
                                    icon="Person"
                                >
                                    <CardBody>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <FormGroup label="NOMBRE:">
                                                    <Link to={`/clients/${data.clientId}/show`} color="primary">
                                                        <p> {data.clientName || ""}</p>
                                                    </Link>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-3">
                                                <FormGroup label="DNI:">
                                                    <p> {data.clientDni || ""}</p>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-3">
                                                <FormGroup label="TELÉFONO:">
                                                    <p> {data.clientPhone || ""}</p>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-3">
                                                <FormGroup label="IBAN:">
                                                    <p> {data.clientIban || ""}</p>
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="row mt-2">
                                            <div className="col-md-4">
                                                <FormGroup label="LOCALIDAD:">
                                                    <p> {data.clientLocality || ""}</p>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-4">
                                                <FormGroup label="PROVINCIA:">
                                                    <p> {data.clientProvince || ""}</p>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-4">
                                                <FormGroup label="DIRECCIÓN:">
                                                    <p> {data.clientAddress || ""}</p>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </CardBody>
                                </CardTabItem>
                            </Card>
                        </div>

                        <Card
                            // @ts-ignore
                            defaultActiveTab={tabURL ? parseInt(tabURL) : activeTab?.activeTab || 0}
                            onActiveTab={(tab: number) => {
                                dispatch(setActiveTab({section: ACTIVE_TAB_SECTION, activeTab: tab}));
                            }}
                            hasTab
                        >
                            <CardTabItem
                                id="operation"
                                title="Timeline estados"
                                icon="Timeline"
                            >
                                <CardBody className="max-h-500 overflow-auto">
                                    {data.walletStatuses.length > 0 ? (
                                        <Timeline noTruncate={true} className=" overflow-auto">
                                            {data.walletStatuses.map(
                                                (actionStatus: any, index: number) => (
                                                    <TimelineItem
                                                        noTruncate={true}
                                                        key={actionStatus.id}
                                                        color={actionStatus.actionStatus.color}
                                                        label={moment(
                                                            actionStatus.createdAt.date
                                                        ).format("YYYY/MM/DD HH:mm")}
                                                    >
                                                        <div>
                                                            Cambio a estado{" "}
                                                            <strong>
                                                                {actionStatus.actionStatus.name ||
                                                                    "En curso"}
                                                            </strong>
                                                        </div>
                                                        <div>
                                                            {" "}
                                                            <p className="text-muted m-0" style={{
                                                                maxWidth: '2000px',
                                                                width: '100%',
                                                                fontSize: '0.875em'
                                                            }}>
                                                                Agente :{" "}{actionStatus.user?.name || "-"}
                                                            </p>
                                                            <p className="text-muted m-0" style={{
                                                                maxWidth: '2000px',
                                                                width: '100%',
                                                                fontSize: '0.875em'
                                                            }}>
                                                                Tipo de Estado
                                                                :{" "}{actionStatus.actionStatus.statusEntity.label || "-"}
                                                            </p>
                                                            <p className="text-muted m-0" style={{
                                                                maxWidth: '2000px',
                                                                width: '100%',
                                                                fontSize: '0.875em'
                                                            }}>
                                                                Comentario :{" "}{actionStatus.comment || "-"}
                                                            </p>
                                                        </div>
                                                    </TimelineItem>
                                                )
                                            )}
                                        </Timeline>
                                    ) : (
                                        <div className="text-center">
                                            No ha cambiado el estado todavia
                                        </div>
                                    )}
                                </CardBody>
                            </CardTabItem>
                            <CardTabItem

                                id="supplies"
                                title="Clientes"
                                icon="People"

                            >
                                <WalletCupsClientCard cups={data.cups}/>
                            </CardTabItem>
                            <CardTabItem
                                id="operations"
                                title="Contratos"
                                icon="Inventory2"
                            >
                                {
                                    Operationdata ? (
                                        <>
                                            <div className="row">
                                                <div className="col-md-12 d-flex justify-content-end">
                                                    <Button
                                                        icon="add"
                                                        color="primary"
                                                        size={'sm'}
                                                        isLight
                                                        onClick={() => {
                                                            setShowCreateOperationModal(true);
                                                        }}
                                                    >
                                                        Nuevo Contrato
                                                    </Button>
                                                </div>
                                            </div>
                                            <OperationTableComponent
                                                data={Operationdata}
                                                error={Operationerror}
                                                filters={filters}
                                                updateFilters={updateFilters}
                                                updateFilterOrder={updateFilterOrder}
                                                updatePage={updatePage}
                                                updatePageSize={updatePageSize}
                                                classNameProps="table table-hover p-3 fs-7"
                                                visibleActions={["show", "editTab", "delete"]}
                                                onClickEdit={(operation: any) => {
                                                    setOperationSelected(operation.id);
                                                    setShowEditOperationModal(true);
                                                }}
                                                visibleColums={["company", "agent", "operationType", "actionStatus", "status", "operationIdentifier", "cups", "clientName", "fav", "clientDni", "createdAt", "product"]}
                                            />
                                        </>
                                    ) : (
                                        <div className="text-center d-flex justify-content-center">
                                            <Spinner/>
                                        </div>
                                    )
                                }
                            </CardTabItem>
                        </Card>
                    </div>
                ) : (
                    <div className="text-center d-flex justify-content-center">
                        <Spinner/>
                    </div>)
            }
            </Page>

            <EditOperationModal visible={showEditOperationModal} setVisible={_handleOnEditModalVisilble}
                                operationId={operationSelected || ""} onOperationEdit={_handleOnOperationEdit}/>

        </Fragment>
    );
};

export default WalletView;
