import { useFormik } from "formik";
import { FC, Fragment, useCallback, useState } from "react";
import Button from "../../components/bootstrap/Button";
import { CardBody, CardFooter, CardFooterRight, CardHeader, CardLabel, CardTitle } from "../../components/bootstrap/Card";
import Checks from "../../components/bootstrap/forms/Checks";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from "../../components/bootstrap/forms/Select";
import Spinner from "../../components/bootstrap/Spinner";
import PlaceholderImage from "../../components/extras/PlaceholderImage";
import * as yup from "yup";
import useFetch from "../../hooks/useFetch";
import { Roles, RolesApiResponse } from "../../type/role-type";
import { CompanyService } from "../../services/companies/companyService";
import { Companies, CompaniesApiResponse } from "../../type/company-type";
import { RoleService } from "../../services/auth/roleService";
import { UserService } from "../../services/users/userService";
import { toast } from "react-toastify";
import AsyncImg from "../../components/AsyncImg";
import { loadUserFromLocalStorage } from "../../utils/jwt";
import { OfficeService } from "../../services/ofiice/officeService";
import { Offices, OfficesApiResponse } from "../../type/office-type";
import { Schema } from "yup";
import { usePrivilege } from "../../components/priviledge/PriviledgeProvider";
import { ValidateDNICIFField } from "../../utils/forms/ValidateDNICIFField";

interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    userData?: any;
}

export interface IUserForm {
    id?: string;
    dni?: string;
    name?: string;
    email?: string;
    address?: string;
    telephone?: string;
    base_salary?: number;
    monthly_objective?: number;
    available_bonuses?: number;
    contract_start_date?: string;
    contract_end_date?: string;
    role?: string;
    password?: string | null;
    re_password?: string | null;
    data_protection?: boolean;
    bank_account?: string;
    company?: string;
    leaddesk_id?: string;
    ocm_id?: string;
    ocm_skill?: string;
    endesaInputChannel?: string;
    office?: string;
}

yup.addMethod(yup.string, 'isValidDNICIF', ValidateDNICIFField);

const UserEditSchema = yup.object({
    dni: yup.string().nullable().notRequired()
    // @ts-ignore
    .isValidDNICIF("Introduce un DNI/CIF válido"),
    name: yup.string().min(1, 'Demasido Corto').max(30, 'Demasiado Largo').required('Campo Obligatorio'),
    email: yup.string().email('Correo Invalido').required('Campo Obligatorio'),
    address: yup.string().nullable(),
    telephone: yup.string().min(9, 'Introduce un número de teléfono válido').required('Campo obligatorio'),
    base_salary: yup.number().min(0, 'Solo Número positivos').nullable(),
    monthly_objective: yup.number().positive('Solo Número positivos').nullable().notRequired(),
    available_bonuses: yup.number().positive('Solo Número positivos').nullable().notRequired(),
    contract_start_date: yup.date().nullable().notRequired(),
    contract_end_date: yup.date().nullable().notRequired(),
    role: yup.string().required('Debes elegir un rol de la organización para poder crear un usuario'),
    bank_account: yup.string().matches(/^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/, 'Cuenta Bancaria Invalida').nullable(),
    password: yup.string().min(8, 'Contraseña de al menos 8 caracteres').max(30, 'Contraseña menor de 30 caracteres').nullable(),
    re_password: yup.string().when('password', {
        is: (val: string | null | undefined) => val !== null && val !== undefined && val.length > 0,
        then: (schema: Schema) => schema.required('Confirmacion de contraseña obligatoria').oneOf([yup.ref('password'), ''], 'Contraseñas no coinciden'),
        otherwise: (schema: Schema) => schema.nullable(),
    }).nullable(),
    leaddesk_id: yup.string().nullable(),
    ocm_id: yup.string().nullable(),
    ocm_skill: yup.string().nullable(),
    office: yup.string().nullable(),
    endesaInputChannel: yup.string().nullable(),
});

const UserCreateSchema = UserEditSchema.concat(
    yup.object({
        role: yup.string().required('Debes elegir un rol de la organización para poder crear un usuario'),
        password: yup.string().required('Contraseña Obligatoria').min(8, 'Contraseña de al menos 8 caracteres').max(30, 'Contraseña menor de 30 caracteres'),
        re_password: yup.string().required('Confirmacion de contraseña obligatoria').oneOf([yup.ref('password'), ''], 'Contraseñas no coinciden'),
    })
);


const UserForm: FC<CreateFormProps> = ({ isLoading, submit, userData }) => {

    const mode = userData ? 'Editar' : 'Crear';

    const { userCan } = usePrivilege();

    const [selectedImage, setSelectedImage] = useState<string | null>(userData?.profileImg?.id || null);

    const userInitialValues: IUserForm = {
        dni: userData?.dni,
        name: userData?.name,
        email: userData?.email,
        address: userData?.address,
        telephone: userData?.telephone,
        base_salary: userData?.base_salary,
        monthly_objective: userData?.monthly_objective,
        available_bonuses: userData?.available_bonuses,
        contract_start_date: userData?.contract_start_date,
        contract_end_date: userData?.contract_end_date,
        role: userData?.role,
        password: null,
        re_password: null,
        data_protection: userData ? userData.data_protection : false,
        bank_account: userData?.bank_account,
        company: userData?.company,
        leaddesk_id: userData?.leaddeskId,
        ocm_id: userData?.ocmId,
        endesaInputChannel: userData?.endesaInputChannel,
        ocm_skill: userData?.ocmSkill,
        office: userData?.office,
    }

    
    const formik = useFormik({
        initialValues: userInitialValues,
        validationSchema: (mode === 'Editar') ? UserEditSchema : UserCreateSchema,
        onSubmit: values => {
            values = {
                ...userData,
                ...values
            }
            
            submit(values)
        },
    });

    const fetchCompanies = useCallback(async () => {

        const companyService = new CompanyService();

        const response = await companyService.getCompanies();
        if (response.getResponseData() == undefined) {
            return ((await new UserService().getUserById(loadUserFromLocalStorage()?.id || '')).getResponseData())
        }
        return response.getResponseData() as CompaniesApiResponse;
    }, []);

    const [companies, fetchingCompanies, companyError] = useFetch(fetchCompanies);

    const fetchRoles = useCallback(async () => {
        const roleService = new RoleService();
        const response = await roleService.listRoles({filter_filters:{ companyid: formik.values.company, active: true }});
        return response.getResponseData() as RolesApiResponse;
    }, [formik.values.company]);

    const [roles, fetchingRoles, roleError] = useFetch(fetchRoles);

    const fetchOffices = useCallback(async () => {
        const officeService = new OfficeService();
        const response = await officeService.getOffices({filter_filters:{ company: formik.values.company }});
        return response.getResponseData() as OfficesApiResponse;
    }, [formik.values.company]);

    const [offices, fetchingOffices, officeError] = useFetch(fetchOffices);

    const handleImageUpload = async (event: React.ChangeEvent<any>) => {
        const file = event.target.files[0];

        if (userData?.id) {
            const response = (await (new UserService()).updateImage(file, userData?.id)).getResponseData()

            if (!response.success) {
                toast.error(response.message);
                return;
            }

            setSelectedImage(response.data.imgProfile);
            toast.success('Imagen actualizada correctamente');
        }


    };

    const clearImage = () => {
        setSelectedImage(null);
        formik.setFieldValue('profile_img', null);
    }

    const getCompaniesList = () => {
        if (companies as Companies) {
            return companies.companies?.map((company: { id: any; name: any; }) => {
                return {
                    value: company.id,
                    label: company.name,
                }
            })
        }
        return [];
    }

    const getCompanyRolesList = () => {
        if (roles as Roles) {
            return roles?.roles?.map((role: { id: any; name: any; }) => {
                return {
                    value: role.id,
                    label: role.name
                }
            })
        }
        return [];
    }

    const getOfficesList = () => {
        if (offices as Offices) {
            return offices.offices.map((office: { id: any; name: any; }) => {
                return {
                    value: office.id,
                    label: office.name
                }
            })
        }
        return [];
    }

    const verifyClass = (inputFieldID: keyof IUserForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '';
    }

    const showErrors = (inputFieldID: keyof IUserForm) => {

        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ?
            <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    }

    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit}>
                <CardBody isScrollable={false}>
                    {mode === "Editar" &&
                        (
                            <div className="row">
                                <div className='col-md-6'>
                                    <CardBody>
                                        <div className="row align-items-center">
                                            <div className='col-lg-3'>
                                                {
                                                    selectedImage
                                                        ? <AsyncImg height={"70px"} width={"70px"} id={selectedImage} />
                                                        : <PlaceholderImage width={70} height={70} className={"rounded"} />
                                                }

                                            </div>
                                            <div className='col-lg-9'>
                                                <div className='row g-4'>
                                                    <div className='col-8'>
                                                        <Input type='file' onChange={(e: React.ChangeEvent<any>) => handleImageUpload(e)}
                                                            autoComplete='photo' />
                                                    </div>
                                                    <div className='col-4'>
                                                        <Button color='dark' isLight icon='Delete' onClick={clearImage} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </div>
                            </div>
                        )
                    }

                    <div className="row">
                        <div className='col-md-6'>
                            <CardHeader className='rounded-1 mb-0'>
                                <CardLabel icon='AccountCircle' iconColor='info'>
                                    <CardTitle>Información personal</CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className="row">
                                    <FormGroup requiredInputLabel label='Nombre y apellidos' className='col-md-6'>
                                        <Input id='name' required onChange={formik.handleChange} value={formik.values.name || ''}
                                            onBlur={formik.handleBlur} className={verifyClass('name')} />
                                        {showErrors('name')}

                                    </FormGroup>
                                    <FormGroup requiredInputLabel label='Email' className='col-md-6'>
                                        <Input id='email' required type='email' onChange={formik.handleChange}
                                            value={formik.values.email || ''} onBlur={formik.handleBlur}
                                            className={verifyClass('email')} />
                                        {showErrors('email')}
                                    </FormGroup>
                                </div>
                                <div className="row mt-2">
                                    <FormGroup label='DNI / CIF' className='col-md-6'>
                                        <Input id='dni' onChange={formik.handleChange} value={formik.values.dni || ''}
                                            onBlur={formik.handleBlur} className={verifyClass('dni')} />
                                        {showErrors('dni')}
                                    </FormGroup>
                                    <FormGroup label='Teléfono' className='col-md-6' requiredInputLabel>
                                        <PhoneInput
                                            country={'es'}
                                            autoFormat={false}
                                            onBlur={() => formik.setFieldTouched('telephone', true)}
                                            countryCodeEditable={false}
                                            onChange={(phone: string) => {
                                                if (phone.length == 0) {
                                                    formik.setFieldValue('telephone', null);
                                                    return;
                                                }
                                                formik.setFieldValue('telephone', "+" + phone);
                                            }}
                                            value={formik.values.telephone} inputClass={verifyClass('telephone')} />
                                        {showErrors('telephone')}
                                    </FormGroup>
                                </div>
                                <div className="row mt-2">
                                    <FormGroup label='Dirección' className='col-md-12'>
                                        <Input id='address' onChange={formik.handleChange} value={formik.values.address || ''}
                                            className={verifyClass('address')} />
                                        {showErrors('address')}
                                    </FormGroup>
                                </div>
                            </CardBody>
                        </div>
                        <div className='col-md-6'>
                            <CardHeader className='rounded-1 mb-0'>
                                <CardLabel icon='Work' iconColor='info'>
                                    <CardTitle>Empresa</CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className="row">
                                    <FormGroup label='Salario Base' className='col-md-6'>
                                        <Input id='base_salary' type='number' onChange={formik.handleChange}
                                            value={formik.values.base_salary || ''} onBlur={formik.handleBlur}
                                            className={verifyClass('base_salary')} />
                                        {showErrors('base_salary')}
                                    </FormGroup>
                                    <FormGroup label='Objetivo mensual (puntos)' className='col-md-6'>
                                        <Input id='monthly_objective' type='number' onChange={formik.handleChange}
                                            value={formik.values.monthly_objective || ''} onBlur={formik.handleBlur}
                                            className={verifyClass('monthly_objective')} />
                                        {showErrors('monthly_objective')}
                                    </FormGroup>
                                </div>
                                <div className="row mt-2">
                                    <FormGroup label='Bonus disponibles' className={`${userCan('list', 'companies') ? 'col-md-6' : 'col-md-12'}`}>
                                        <Input id="available_bonuses" type='number' onChange={formik.handleChange}
                                            value={formik.values.available_bonuses || ''} onBlur={formik.handleBlur}
                                            className={verifyClass('available_bonuses')} />
                                        {showErrors('available_bonuses')}
                                    </FormGroup>
                                    
                                    {userCan('list', 'companies') ?
                                        (
                                            <FormGroup label='Organización' className='col-md-6'>
                                                <Select id='company' onChange={formik.handleChange} className={verifyClass('company')}
                                                    value={formik.values.company || ''} ariaLabel='Default select example'
                                                    placeholder='Elegir organización...'
                                                    list={getCompaniesList()}
                                                />
                                                {showErrors('company')}
                                            </FormGroup>
                                        )
                                        : <></>
                                    }

                                </div>
                                <div className="row">
                                    <FormGroup id='role' requiredInputLabel label='Rol'>
                                        <Select id='role' className={verifyClass('role')} ariaLabel='Default select example'
                                            placeholder='Elegir rol...'
                                            onChange={formik.handleChange} value={formik.values.role ? formik.values.role.toString() : ''}
                                            list={getCompanyRolesList()}
                                        />
                                        {showErrors('role')}
                                    </FormGroup>
                                </div>
                            </CardBody>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-3'>
                            <CardHeader className='rounded-1 mb-0'>
                                <CardLabel icon='Assignment' iconColor='info'>
                                    <CardTitle>Contrato</CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className="row">
                                    <FormGroup label='Inicio de contrato' className='col-md-6'>
                                        <Input id='contract_start_date' type='date' onChange={formik.handleChange}
                                            value={formik.values.contract_start_date || ''}
                                            className={verifyClass('contract_start_date')} />
                                        {showErrors('contract_start_date')}
                                    </FormGroup>
                                    <FormGroup label='Fin de contrato' className='col-md-6'>
                                        <Input id='contract_end_date' type='date' onChange={formik.handleChange}
                                            value={formik.values.contract_end_date || ''} className={verifyClass('contract_end_date')} />
                                        {showErrors('contract_end_date')}
                                    </FormGroup>
                                </div>
                                <div className="row mt-2">
                                    <FormGroup label='Cuenta bancaria' className='col-md-12'>
                                        <Input id='bank_account' onChange={formik.handleChange} value={formik.values.bank_account || ''}
                                            onBlur={formik.handleBlur} className={verifyClass('bank_account')} />
                                        {showErrors('bank_account')}
                                    </FormGroup>
                                </div>
                                <div className="row mt-4">
                                    <FormGroup className='col-md-12'>
                                        <Checks type='checkbox' id='data_protection' name='data_protection' label='Protección de datos'
                                            onChange={formik.handleChange} checked={formik.values.data_protection || false}
                                            className={verifyClass('data_protection')}
                                        />
                                        {showErrors('data_protection')}
                                    </FormGroup>
                                </div>
                            </CardBody>
                        </div>
                        <div className='col-md-3'>
                            <CardHeader className='rounded-1 mb-0'>
                                <CardLabel icon='Login' iconColor='info'>
                                    <CardTitle>Canales</CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className="row">
                                    <FormGroup label='Canal de Entrada Endesa' className='col-md-12'>
                                        <Input id='endesaInputChannel' type={'text'} onChange={formik.handleChange}
                                            value={formik.values.endesaInputChannel || ''} onBlur={formik.handleBlur}
                                            className={verifyClass('endesaInputChannel')} />
                                        {showErrors('endesaInputChannel')}
                                    </FormGroup>
                                </div>
                            </CardBody>
                        </div>
                        <div className='col-md-6'>
                            <div className="row">
                                <div className="col-md-6">
                                    <CardHeader className='rounded-1 mb-0'>
                                        <CardLabel icon='Extension' iconColor='info'>
                                            <CardTitle>Integraciones</CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="row mt-2">
                                            <FormGroup label='LeadDesk Id' className='col-md-12'>
                                                <label className="float-end">
                                                    <a href="/media/documentation/LeadDesk/leadDesk_get_id.webm" target="_blank">
                                                        Ver video
                                                    </a>
                                                </label>
                                                <Input id='leaddesk_id' type={'text'} onChange={formik.handleChange}
                                                    value={formik.values.leaddesk_id || ''} onBlur={formik.handleBlur}
                                                    className={verifyClass('leaddesk_id')} />
                                                {showErrors('leaddesk_id')}
                                            </FormGroup>

                                            <FormGroup label='OCM Id' className='col-md-12 mt-2'>
                                                <label className="float-end">
                                                    <a href="/media/documentation/ocm/OCM_show_user_id_compressed.mp4" target="_blank">
                                                        Ver video
                                                    </a>
                                                </label>
                                                <Input id='ocm_id' type={'text'} onChange={formik.handleChange}
                                                    value={formik.values.ocm_id || ''} onBlur={formik.handleBlur}
                                                    className={verifyClass('ocm_id')} />
                                                {showErrors('ocm_id')}
                                                <label className="text-muted">* Login de usuario, no el nombre, todo en minúsculas</label>
                                            </FormGroup>

                                            <FormGroup label='OCM Skill' className='col-md-12 mt-2'>
                                                <label className="float-end">
                                                    <a href="/media/documentation/ocm/OCM_show_user_id_compressed.mp4" target="_blank">
                                                        Ver video
                                                    </a>
                                                </label>
                                                <Input id='ocm_skill' type={'text'} onChange={formik.handleChange}
                                                       value={formik.values.ocm_skill || ''} onBlur={formik.handleBlur}
                                                       className={verifyClass('ocm_skill')} />
                                                {showErrors('ocm_skill')}
                                                <label className="text-muted">* Skill de salida de usuario</label>
                                            </FormGroup>
                                        </div>
                                    </CardBody>
                                </div>
                                <div className="col-md-6">
                                    <CardHeader className='rounded-1 mb-0'>
                                        <CardLabel icon='Business' iconColor='info'>
                                            <CardTitle>Oficina</CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="row mt-2">
                                            <FormGroup label='Oficina' className='col-md-12' requiredInputLabel>
                                                <Select id='office' onChange={formik.handleChange} className={verifyClass('office')} required
                                                    value={formik.values.office || ''} ariaLabel='Default select example'
                                                    placeholder='Elegir Oficina...'
                                                    list={getOfficesList()}
                                                />
                                                {showErrors('office')}
                                            </FormGroup>
                                        </div>
                                    </CardBody>
                                </div>
                            </div>
                            <CardHeader className='rounded-1 mb-0'>
                                <CardLabel icon='Security' iconColor='info'>
                                    <CardTitle>Seguridad</CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className="row mt-2">
                                    <FormGroup label='Contraseña' className='col-md-6'>
                                        <Input id='password' type={'password'} autoComplete="new-password" onChange={formik.handleChange}
                                            value={formik.values.password || ''} onBlur={formik.handleBlur}
                                            className={verifyClass('password')} />
                                        {showErrors('password')}
                                    </FormGroup>
                                    <FormGroup label='Confirmar Contraseña' className='col-md-6'>
                                        <Input id='re_password' type={'password'} autoComplete="new-password" onChange={formik.handleChange}
                                            value={formik.values.re_password || ''} onBlur={formik.handleBlur}
                                            className={verifyClass('re_password')} />
                                        {showErrors('re_password')}
                                    </FormGroup>
                                </div>
                            </CardBody>
                        </div>
                    </div>
                </CardBody>
                <CardFooter>
                    <CardFooterRight>
                        <Button type="submit" size='lg' color='primary'>
                            {isLoading ? <Spinner isSmall /> : `${mode} Usuario`}
                        </Button>
                    </CardFooterRight>
                </CardFooter>
            </form>
        </Fragment>
    )
}

export default UserForm;